import React, { useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import Loadable from 'modules/loading/Loadable'
import { useCurrentUserId } from './features/users/useCurrentUserId'
import { WorkspaceCreate } from 'features/workspaces/WorkspaceCreate'
import { WorkspaceRedirect } from 'features/workspaces/WorkspaceRedirect'
import { find } from 'lodash'
import LoginCodePage from 'features/users/LoginCodePage'

const LoginPage = Loadable(() => import('./features/users/LoginPage'))
const PasswordResetChangePage = Loadable(() => import('./features/users/PasswordResetChangePage'))
const PasswordResetRequestPage = Loadable(() => import('./features/users/PasswordResetRequestPage'))
const WorkspaceRoutes = Loadable(() => import('./features/workspaces/WorkspaceRoutes'))
const InterviewRoutes = Loadable(() => import('./features/interview/InterviewRoutes'))

const PUBLIC_PATHS = ['/login', '/signup', '/i', '/interview-generator']

export default function AppRouter () {
  const [userId, userIdLoading] = useCurrentUserId()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/') return navigate('/w')
    if (userIdLoading || userId || find(PUBLIC_PATHS, (path) => location.pathname.startsWith(path))) return
    navigate('/login', {
      state: {
        redirectTo: location.pathname,
        redirectState: location.state,
      },
    })
  }, [location.pathname, location.state, navigate, userId, userIdLoading])

  return (
    <Routes>
      <Route path='/login' element={<LoginPage type='Login' />} />
      <Route path='/signup' element={<LoginPage type='Sign up' />}/>
      <Route path='/login/withemail' element={<LoginPage type='Login with E-mail' />}/>
      <Route path='/login/withcode' element={<LoginCodePage />}/>
      <Route path='/login/reset-password' element={<PasswordResetRequestPage />} />
      <Route path='/login/change-password' element={<PasswordResetChangePage />} />
      <Route path='/i/:jobId/*' element={<InterviewRoutes />} />
      <Route path='/w/create' element={<WorkspaceCreate />} />
      <Route path='/w/:workspaceSlug/*' element={<WorkspaceRoutes />} />
      <Route path='/w' element={<WorkspaceRedirect />} />
    </Routes>
  )
}
