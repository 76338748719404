import { useEffect } from 'react'
import { useCurrentUser } from 'features/users/useCurrentUser'
import { useNavigate } from 'react-router-dom'
import { useCreateWorkspaceMutation } from 'apollo'
import { Layout } from 'features/common/Layout'
import { Loading } from 'modules/loading/Loading'

export function WorkspaceRedirect () {
  const [user, userLoading] = useCurrentUser()
  const navigate = useNavigate()
  const [createWorkspace] = useCreateWorkspaceMutation()

  const lastWorkspaceSlug = user?.activeWorkspace
  const workspaces = user?.workspaces

  useEffect(() => {
    if (userLoading) return
    if (lastWorkspaceSlug) navigate(`/w/${lastWorkspaceSlug}`)
    else if (workspaces?.length) navigate(`/w/${workspaces[0]?.workspace.slug}`)
    else {
      navigate('/w/create')
      // (async () => {
      //   const res = await createWorkspace({
      //     variables: {
      //       input: {},
      //     },
      //   })
      //   const slug = res.data?.createWorkspace?.slug
      //   if (!slug) navigate('/w/create')
      //   else navigate(`/w/${slug}`)
      // })()
    }
  }, [createWorkspace, lastWorkspaceSlug, navigate, userLoading, workspaces, workspaces?.length])

  return (
    <Layout>
      <Loading loading={true} center />
    </Layout>
  )
}
