import * as React from 'react'
import {
  ChakraProvider,
} from '@chakra-ui/react'
import { ApolloProvider } from '@apollo/client'
import { ModalProvider } from '@1productaweek/react-modal-hooks'
import { Global } from '@emotion/react'
import globalStyles from './globalStyles'
import { BrowserRouter as Router } from 'react-router-dom'
import theme from './theme'
import AppRoutes from './AppRoutes'
import apollo from 'config/apollo'
import ScrollToTop from 'modules/common/ScrollToTop'
import PostHogPageView from 'modules/common/PostHogPageView'
import { ApiProvider } from 'features/common/ApiProvider'
import { AuthProvider } from 'features/users/AuthProvider'
import { CrispProvider } from 'modules/common/Crisp'

export const App = () => {
  return (
    <ApolloProvider client={apollo}>
      <AuthProvider
        domain={process.env.REACT_APP_DOMAIN}
        storagePrefix={process.env.REACT_APP_AUTH_STORAGE_PREFIX}
      >
        <ApiProvider baseURL={process.env.REACT_APP_API_URL}>
          <ChakraProvider theme={theme}>
            <Global styles={[globalStyles]} />
            <ModalProvider>
              <CrispProvider>
                <Router>
                  <PostHogPageView />
                  <ScrollToTop />
                  <AppRoutes />
                </Router>
              </CrispProvider>
            </ModalProvider>
          </ChakraProvider>
        </ApiProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}
