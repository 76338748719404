import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    body: 'Lato',
    heading: 'Lato',
    mono: 'Menlo, monospace',
  },
  components: {
    FormLabel: {
      baseStyle: {
        fontWeight: 'bold',
      },
    },
  },
  textStyles: {
    note: {
      color: 'gray.600',
      fontSize: 'sm',
    },
  },
  layerStyles: {
    card: {
      border: '1px solid',
      borderColor: 'gray.300',
      _hover: {
        bg: 'gray.100',
      },
    },
    cardSelected: {
      border: '1px solid',
      borderColor: 'green.500',
      bg: 'green.100',
      color: 'green.800',
    },
    list: {
      _hover: {
        bg: 'gray.100',
      },
    },
  },
  colors: {
    brand: '#183765',
    blue: {
      50: '#EAF1FA',
      100: '#C5D7F1',
      200: '#A0BDE8',
      300: '#7BA4E0',
      400: '#568AD7',
      500: '#3170CE',
      600: '#275AA5',
      700: '#1D437C',
      800: '#142D52',
      900: '#0A1629',
    },
  },
})

export default theme
