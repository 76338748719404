import { ApolloClient, ApolloLink, InMemoryCache, concat } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
// import { persistCache } from 'apollo-cache-persist'
// import localforage from 'localforage'
// import { AnyQueryKey } from 'react-query'
// import { uniqBy } from 'lodash'

const {
  REACT_APP_API_URL,
  REACT_APP_AUTH_STORAGE_PREFIX = 'hirestack.',
} = process.env

const httpLink = new BatchHttpLink({
  uri: `${REACT_APP_API_URL}/graphql`,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const tokenPath = `${REACT_APP_AUTH_STORAGE_PREFIX}token`
  const token = Cookies.get(tokenPath)
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  })

  return forward(operation)
})

export const cache = new InMemoryCache({
  resultCaching: true,
  addTypename: true,
  typePolicies: {
    Query: {
      fields: {
        // Return reference data
        getWorkspace (_, { args, toReference }) {
          return toReference({
            __typename: 'Workspace',
            id: args?.id,
          })
        },
      },
    },
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err: any) => {
      err.reason = err?.extensions?.exception?.reason
      if (process.env.NODE_ENV === 'production') {
        Sentry.addBreadcrumb({
          category: 'graphql',
          message: err.message,
          level: Sentry.Severity.Error,
          data: err,
        })
      }
      if (err.reason === 'auth/unauthenticated') {
        // cache.reset()
        // window.location.href = '/login'
      }
    })
  }
})

// Await the result of persist cache, we'll need to use useEffect and simply
// wait for the promise to resolve. We need to have update scripts working
// before persiting cache, so we can reset on update
// persistCache({
//   cache,
//   storage: localforage as any,
// })

const client = new ApolloClient({
  cache,
  link: concat(errorLink, concat(authMiddleware, httpLink)),
})

export default client
