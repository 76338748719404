import { useMeQuery, MeQuery } from 'apollo'
import { useCurrentUserId } from './useCurrentUserId'
import cleanDeep from 'clean-deep'

export function useCurrentUser (): [MeQuery['me']|null, boolean] {
  const [userId, userIdLoading] = useCurrentUserId()
  const { data: meQuery, loading } = useMeQuery({
    skip: !userId || userIdLoading,
    nextFetchPolicy: 'network-only',
  })
  return [meQuery?.me ? cleanDeep(meQuery?.me) : null, userIdLoading || loading]
}
