import * as React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import posthog from 'posthog-js'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import pkg from '../package.json'
// import '@fontsource/lato'
import '@fontsource/lato/400.css'
import '@fontsource/lato/700.css'
// import '@fontsource/source-sans-pro/400.css'
// import '@fontsource/source-sans-pro/600.css'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://832cd59b85b247c09722aa7b9f956039@o1114747.ingest.sentry.io/6146141',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV_NAME,
    release: pkg.version,
  })
}

// Event capturing etc
posthog.init('phc_BxuI8jNRFNmfrEJrEEtuvo7JfSdXfr3LGHTcONqxVVp', { api_host: 'https://app.posthog.com' })
if (process.env.REACT_APP_ENV_NAME !== 'production') {
  posthog.opt_out_capturing()
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
