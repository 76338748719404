import React from 'react'
import { Button, Box, Heading, Text, Stack, Center, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useAsyncCallback } from 'modules/common/useAsyncCallback'
import { useLocation, useNavigate } from 'react-router-dom'
import { Layout } from 'features/common/Layout'
import { useCreateWorkspaceMutation } from 'apollo'

export interface WorkspaceCreateData {
  name: string
  slug: string
}

export function WorkspaceCreate () {
  const navigate = useNavigate()
  const [createWorkspace] = useCreateWorkspaceMutation()
  const location = useLocation()
  const locationState = location?.state as any

  const {
    register,
    handleSubmit,
    getValues,
  } = useForm<WorkspaceCreateData>()

  const onSubmit = useAsyncCallback(async ({ name }: WorkspaceCreateData) => {
    const resp = await createWorkspace({
      variables: {
        input: {
          name,
          plan: locationState?.planId as string|undefined,
        },
      },
      refetchQueries: 'all',
    })

    const slug = resp?.data?.createWorkspace?.slug
    if (slug) navigate(`/w/${slug}`)
  })

  return (
    <Layout>
      <Center height='50%'>
        <Box maxW='32em' minW='20em'>
          <form
            onSubmit={handleSubmit(onSubmit.execute)}
          >
            <Stack spacing={4}>
              <Heading size='lg'>Create Workspace</Heading>
              <Text color='gray.500' fontSize='lg'>A workspace is usually the name of your organization or company. You can invite other teammates to this workspace later.</Text>
              <FormControl id='name'>
                <FormLabel fontWeight='bold'>Workspace Name</FormLabel>
                <Input isRequired type='text' placeholder='Name of workspace' data-type='text' {...register('name')} />
              </FormControl>
              <Button
                isLoading={onSubmit.loading}
                type='submit'
                colorScheme='blue'
              >
                Create Workspace
              </Button>
              <Button
                isLoading={onSubmit.loading}
                onClick={() => {
                  onSubmit.execute(getValues())
                }}
                variant='ghost'
              >
                Skip
              </Button>
            </Stack>
          </form>
        </Box>
      </Center>
    </Layout>
  )
}
