import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRequestLoginUserEmailMutation, useLoginUserWithTokenMutation } from 'apollo'
import queryString from 'query-string'
import { useAsyncCallback } from 'modules/common/useAsyncCallback'
import { useLoginToken } from './useLoginToken'
import { useAuth } from './useAuth'

export interface UseLoginCodeParams {
  email?: string
  redirectTo?: string
}

export function useLoginCode ({ email, redirectTo }: UseLoginCodeParams) {
  const codeUsedRef = useRef(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { logout, auth, loading } = useAuth()

  const [requestLoginUserEmail] = useRequestLoginUserEmailMutation()
  const [loginUserWithToken] = useLoginUserWithTokenMutation()
  const login = useLoginToken()

  const query = queryString.parse(location.search) ?? {}
  const skipIfLoggedIn = query.force === 'false'
  const loginCode = query.code
  const redirectFromUrl = query.redirect as string
  const emailFromUrl = query.email as string

  const resendEmail = useAsyncCallback(async () => {
    const e = email ?? emailFromUrl
    if (!e) return
    return requestLoginUserEmail({
      variables: { input: { email: e } },
    })
  })

  const loginWithCode = useAsyncCallback(async (code: string) => {
    const to = redirectTo ?? redirectFromUrl ?? '/w'
    const resp = await loginUserWithToken({
      variables: { input: { token: code } },
    }).catch((err) => {
      if (skipIfLoggedIn && auth?.userId) {
        if (to.startsWith('http')) window.location.href = to
        else return navigate(to)
      }
      throw err
    })

    if (!resp) return

    await logout()
    const { token, user } = resp?.data?.loginUserWithToken || {}
    const userId = user?.id
    if (!token || !userId) throw new Error('Unable to login')
    await login(token, userId, user?.email ?? undefined, false)
    if (to.startsWith('http'))  window.location.href = to
    else navigate(to)
  }, {
    logError: false,
  })

  useEffect(() => {
    if (!loginCode || codeUsedRef.current || (loading && skipIfLoggedIn)) return
    codeUsedRef.current = true
    const code = Array.isArray(loginCode) ? loginCode[0] : loginCode
    if (!code) return
    loginWithCode.execute(code)
  }, [loading, loginCode, loginWithCode, skipIfLoggedIn])

  return {
    resendEmail,
    loginWithCode,
  }
}
