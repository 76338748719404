import React from 'react'
import { css } from '@emotion/react'
import { Link, useLocation } from 'react-router-dom'
import { Input, Button, Box, Stack } from '@chakra-ui/react'
import { Loading } from 'modules/loading/Loading'
import { useForm } from 'react-hook-form'
import { useAsyncCallback } from 'modules/common/useAsyncCallback'
import { useLoginCode } from './useLoginCode'

export interface LoginCodeFormProps {
  email?: string
  redirectPath?: string
}

export function LoginCodeForm ({ email, redirectPath }: LoginCodeFormProps) {
  const location = useLocation()
  const { resendEmail, loginWithCode } = useLoginCode({
    email,
    redirectTo: redirectPath,
  })

  const {
    handleSubmit,
    register,
  } = useForm()

  const onSubmitCode = useAsyncCallback(async ({ code }) => {
    if (!code) throw new Error('Invalid code')
    // Login with basic code
    loginWithCode.execute(code?.trim())
  })

  return (
    <Loading loading={onSubmitCode.loading}>
      <Box>
        <form onSubmit={handleSubmit(onSubmitCode.execute)}>
          <Stack spacing={4}>
            <Box>
              <Input
                variant='filled'
                placeholder='Enter code from e-mail'
                spellCheck={false}
                data-cy='code'
                {...register('code')}
              />
            </Box>

            <Button
              type='submit'
              colorScheme='blue'
            >
              Login
            </Button>
            <Box css={css`margin-top: 1em;`}>
              <Button
                variant='link'
                size='xs'
                disabled={!email}
                onClick={resendEmail.execute}
                isLoading={resendEmail.loading}
              >Click here to resend email</Button>
            </Box>
            <Box css={css`margin-top: 2em; color: #999; font-size: 0.9em;`}>
              Need to login with a different e-mail?
              <Link
                to={{
                  pathname: '/login',
                  search: location.search,
                // state: { email: formData.email, password: formData.password },
                }}
              >
                &nbsp;Back to Login
              </Link>
            </Box>
          </Stack>
        </form>
      </Box>
    </Loading>

  )
}
